import { render, staticRenderFns } from "./MyCreate.vue?vue&type=template&id=91c74480&scoped=true"
import script from "./MyCreate.vue?vue&type=script&lang=js"
export * from "./MyCreate.vue?vue&type=script&lang=js"
import style0 from "./MyCreate.vue?vue&type=style&index=0&id=91c74480&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91c74480",
  null
  
)

export default component.exports